
import React from 'react';
import './Styles.css';

const Hero = () => {
  return (
    <section id="home" className="hero">
      <div className="hero-content">
        <h3>Find Your Favorite Food</h3>
        <br/>
        <p>Discover the best food & drinks  near you.</p>
    <br/>
        <div className="search-bar">
          <input type="text" placeholder="Search for restaurants, dishes..." />
          <button>Search</button>
        </div>
      </div>
    </section>
  );
};

export default Hero;
