import React from 'react'

const ShippingPolicy = () => {
  return (
    <div className='shipping-policy-container'>
      <b>Shipping & Delivery Policy</b>
      <p>For International buyers, orders are shipped and delivered through registered international courier companies and/or International speed post only. For domestic buyers, orders are shipped through registered domestic courier companies and /or speed post only. Orders are shipped within 0-7 days or as per the delivery date agreed at the time of order confirmation and delivering of the shipment subject to Courier Company / post office norms. DINE DEAL TECH LLP is not liable for any delay in delivery by the courier company / postal authorities and only guarantees to hand over the consignment to the courier company or postal authorities within 0-7 days rom the date of the order and payment or as per the delivery date agreed at the time of order confirmation. Delivery of all orders will be to the address provided by the buyer. Delivery of our services will be confirmed on your mail ID as specified during registration. For any issues in utilizing our services you may contact our helpdesk on 9673307070 or dinedealtech@gmail.com</p>
      <p><i>Disclaimer: The above content is created at DINE DEAL TECH LLP's sole discretion. Razorpay shall not be liable for any content provided here and shall not be responsible for any claims and liability that may arise due to merchant’s non-adherence to it.</i></p>
    </div>
  )
}

export default ShippingPolicy
