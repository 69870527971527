


import React from 'react';
import './Styles.css';
import logo from '../Images/logo.jpg';

const Header = () => {
  return (
    <header className="header">
      <div className="container d-flex align-items-center justify-content-between">
        <div className="logo">
          <img src={logo} alt="Dine Deal Logo" className="logo-img" />
          <p>Dine Deal Tech LLP</p>
        </div>
        <nav className="nav">
          <a href="/">Home</a>
          <a href="#categories">Categories</a>
          <a href="#restaurants">Restaurants</a>
        </nav>
      </div>
    </header>
  );
};

export default Header;
