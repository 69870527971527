
import React from 'react';
import './Styles.css';

const Restaurant = () => {
  const restaurants = [
    { name: 'The Burger House', description: 'Best burgers in town.', image: 'https://www.shutterstock.com/image-photo/classic-hamburger-stock-photo-isolated-600nw-2282033179.jpg' },
    { name: 'Pizza Palace', description: 'Delicious pizzas and more.', image: 'https://i0.wp.com/picjumbo.com/wp-content/uploads/yummy-and-tasty-salami-pizza-diavola-close-up-free-photo.jpg?w=600&quality=80' },
    { name: 'China Town', description: 'Authentic Chinese food.', image: 'https://img-cdn-china-admissions.imgix.net/wp-content/uploads/2020/02/Chinese-food.jpg?auto=format%2Cenhance%2Ccompress' },
  ];
  
  return (
    <section id="restaurants" className="restaurant-section">
      <div className="container">
        <h2>Collections</h2>
        <div className="restaurant-list">
          {restaurants.map((restaurant, index) => (
            <div key={index} className="restaurant-item">
              <img src={restaurant.image} alt={restaurant.name} className="restaurant-image" />
              <h3 className="restaurant-name">{restaurant.name}</h3>
              <p className="restaurant-description">{restaurant.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Restaurant;
