import React from 'react'
import Restaurant from './Restaurants'
import Category from './Categories'
import Hero from './Hero'
import TermsConditions from './TermsConditions'


const Home = () => {
  return (
    <div>
    <Hero/>
    <Category/>
      <Restaurant/>
    
    </div>
  )
}

export default Home
