
import React from 'react';
import './Styles.css';

const Category = () => {
  const categories = [
    { name: 'Burgers', link: '/category/burgers' },
    { name: 'Pizza', link: '/category/pizza' },
    { name: 'Chinese', link: '/category/chinese' },
    { name: 'Indian', link: '/category/indian' },
  ]
  return (
    <section id="categories" className="category-section">
      <div className="container">
        <h2>Browse by Category</h2>
        <div className="category-list">
          {categories.map((category, index) => (
            <div key={index} className="category-item">
              <a href={category.link} className="category-link">
                {category.name}
              </a>
            </div>
            
          ))}
        </div>
      </div>
    </section>
    
    
  );
};

export default Category;